<template>
  <div>
    <div>
      <b-row class="text-center">
        <b-col md="10" class="mx-auto">
          <h1 class="mt-1">Our App</h1>
          <p class="mb-2 pb-75">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam
            eveniet fuga eligendi, illo temporibus adipisci veniam dolorum quam
            quasi quis, debitis assumenda alias cumque?
    
          </p>
        </b-col>
      </b-row>

      <b-row class="product-card" v-if="getAppInfo">
        <b-col md="11" class="mx-auto">
          <b-row class="match-height">
            <b-col
              md="6"
              xl="4"
              v-for="(appItem, index) in getAppInfo"
              :key="index"
            >
              <!-- crousel -->

              <b-card :title="appItem.name"  no-body>
                <b-carousel
                  id="carousel-1"
                  ref="myCarousel"
                  controls
                  indicators
                  background=" #000000"
                  fade
                  img-width="924"
                  img-height="380"
                  style="padding-bottom:1.5rem"
                  :interval="4000"
                >
                  
                <a href="#" @click="openCrosualModal(appItem)">
                   <b-carousel-slide
                      background="#0d0d0d"
                      v-for="(itemIcon, index) in appItem.icon.split(',')"
                      :key="index"
                      :img-src="itemIcon"
                    /> 
                 
                  </a>
                    <!--  <b-carousel-slide :img-src="require('@/assets/images/slider/01.jpg')"   />
      <b-carousel-slide :img-src="require('@/assets/images/slider/02.jpg')"  />
      <b-carousel-slide :img-src="require('@/assets/images/slider/03.jpg')" /> -->
                   
                </b-carousel>
                <b-card-body>
                  <b-card-title class="mb-1">{{ appItem.name }}</b-card-title>
                  <b-card-text>
                    {{ appItem.description }}
                  </b-card-text>
                </b-card-body>
                <b-card-footer
                  class="d-flex justify-content-between align-items-center p-1"
                >
                
                   <b-button
                    variant="success"
                    size="sm"
                    class="ml-1"
                    @click="launchPlugin(appItem.app_url, appItem.code)"
                  >
                    Launch App
                  </b-button>


                  <b-button
                    variant="danger"
                    size="sm"
                    class="ml-1"
                    v-if="appItem.subscription_status === 'Expired'"
                    disabled
                  >
                    {{ appItem.subscription_status }}
                  </b-button>
                </b-card-footer>
              </b-card>

            
              
            </b-col>
          </b-row>
        </b-col>
      </b-row>
     

      <!-- modal subscription expired -->
      <div class="subExpired">
        <b-modal
          v-model="modalShow"
          no-close-on-backdrop
          title="Subscription Plan Status"
          ok-title="Pay Now"
          ok-only
          ok-variant="danger"
          centered
          header-bg-variant="danger"
          title-class="text-white"
          @ok="handkePlanExpires"
 
         
        >
          <b-card-text>
            <p>
              Your suscription plan has been expired please renew your
              subscription to continue the service!
            </p>
          </b-card-text>
        </b-modal>
      </div>
      <!--/ modal subscription expired -->
      <!--modal launch app -->
      <b-modal
        id="modal-1"
        title="Install App"
        cancel-title="Cancle"
        ok-title="Install"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="closeModal"
        @ok="handleOk"
      >
        <b-card-text>
          <h5>Check First Paragraph</h5>
          Oat cake ice cream candy chocolate cake chocolate cake cotton candy
          dragée apple pie. Brownie carrot cake candy canes bonbon fruitcake
          topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake
          liquorice.
        </b-card-text>
      </b-modal>
      <!--/ modal launch app -->
      <!--modal carsoul app -->
      <b-modal
        id="modal-2"
        v-model="showCrosualModal"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-header="true"
        :hide-footer="true"
      >
        
        <b-card-text>
         
                 
                  
          <b-carousel id="carousel-caption" slide :interval="4000" controls indicators v-if="appInfo.hasOwnProperty('icon')">
          
           
           <b-carousel-slide
                      background="#0d0d0d"
                      v-for="(itemIcon, index) in appInfo.icon.split(',')"
                      :key="index"
                      :img-src="itemIcon"
                    /> 
                  
                   
                   
                </b-carousel>
          </b-carousel>
        </b-card-text>
          <b-card-body>
            <b-card-title class="mb-1">{{ appInfo.name }}</b-card-title>
            <b-card-text class="mb-0">
              {{ appInfo.description }}
            </b-card-text>

            <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(listItem, index) in appInfo.installation_instruction"
                    :key="index"
                    class="pr-0"
                  >
                    {{ listItem }}
                  </b-list-group-item>
                </b-list-group>

                

          </b-card-body>
          <b-card-footer
            class="d-flex justify-content-between align-items-center p-1"
          >
          

            <b-button
              variant="success"
              size="sm"
              class="ml-1"
              @click="launchPlugin(appInfo.app_url, appInfo.code)"
            >
              Launch App
            </b-button>

            <b-button
              variant="danger"
              size="sm"
              class="ml-1"
              v-if="appInfo.subscription_status === 'Expired'"
              disabled
            >
              {{ appInfo.subscription_status }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="(showCrosualModal = false), (appInfo = {})"
            >
            Close
              <!-- <feather-icon icon="MinimizeIcon" /> -->
            </b-button>
          </b-card-footer>
    
      </b-modal>
      <!--/ modal carsoul app -->
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BButton,
  BOverlay,
  BCarousel,
  BCarouselSlide,
   BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";

import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import store from "@/store/index";
import axiosIns from "@/libs/axios";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
    BOverlay,
    BCarousel,
    BCarouselSlide,

    BCardCode,
    store,
     BListGroup,
  BListGroupItem,
  },
  data() {
    return {
      // subscribeDashboardStats: null,
      appIcons: {
        SP001: require("@/assets/images/apps/support-img.jpg"),
        SM001: require("@/assets/images/apps/social-media-img.jpg"),
      },
      modalShow: false,
      show: true,
      appInfo: {},
      showCrosualModal: false,
      imageList: {}
    };
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      getAppInfo: "app-dashboard/getAppInfo",
      getSubscriptionInfo: "app-dashboard/getSubscriptionInfo",
    })

  },
  watch: {
//  getSubscriptionInfo(newVal) {
//    if (newVal.subscription_status == "Expired") this.modalShow = true;
//       else this.modalShow = false; 
//       this.modalShow = true;
//     },
    getSubscriptionInfo(newVal) {
      if (newVal.subscription_status == "Expired") this.modalShow = true;
      else this.modalShow = false;
    },
  },
  created() {
    this.fetchSubscribeDashboard();
    // this.launchPlugin();
    if (!this.$cookies.get("token")) this.$router.push("/login");
 
  },
  methods: {

    fetchSubscribeDashboard() {
      store.dispatch("app-dashboard/fetchSubscribeDashboard").then(() => {
        //console.log('our response1', response);
        // this.subscribeDashboardStats = response.data.data.apps;
        // console.log('our response2', this.subscribeDashboardStats);
      });
    },
    launchPlugin(url, code) {
     /*  axiosIns
        .get("/subscription/app/login/", {
          params: {
            app_name: code,
          },
        })
        .then((response) => {
          console.log("app name jwt_token:-", response.data.data.jwt_token);
          //https:vuexy-support.web.app/#/temp-user?token=
          if (url) {
            url = url + response.data.data.jwt_token;
            window.open(url);
          }
        })
        .catch((error) => {
          reject(error);
        }); */
        if(url){
          url = url + this.$cookies.get("token")
          window.open(url);
        }
    },
    /* modal methods */
    resetModal() {
      console.log("hidden show");
    },
    closeModal() {
      console.log("close");
    },
    handleOk(bvModalEvt) {
      console.log("handleOk", bvModalEvt);
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      //this.handleSubmit()
    },
    handkePlanExpires(bvModalEvt){
      bvModalEvt.preventDefault();
      // this.$route.push('/subscription-plan');
      this.$router.push('/subscription-plan')
    },
    openCrosualModal(appItem) {
      this.appInfo = appItem;
      this.showCrosualModal = true;
    },
  },
};
</script>

<style >
.modal-header .close {
  display: none;
}
/* .carousel-control-next .carousel-control-next-icon {
    background-color: black !important;
    #161d313d
} */
.carousel.slide,
.carousel.slide a:hover {
  background-color: #161d313d;
}
.carousel-indicators{
  margin-bottom: 0 !important;
}
</style>
